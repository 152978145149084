















































import { BrandQueriesService, BrandQueryHistoryItem, BrandQueryResult, BrandQueryResultItem } from '@/api/braendz';
import { BusyObject } from '@/models/Busy';
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';

import VueSlickCarousel from 'vue-slick-carousel';
import Carousel3dSlider from '@/components/Carousels/Carousel3dSlider.vue';
import BrandListItem from '@/components/Brands/BrandListItem.vue';
import VerticalBrandTile from '@/components/Brands/VerticalBrandTile.vue';
import HorizontalBrandTile from '@/components/Brands/HorizontalBrandTile.vue';

@Component({
    components: {
        BrandListItem,
        VerticalBrandTile,
        VueSlickCarousel,
        Carousel3dSlider,
        HorizontalBrandTile
    },
    model: {
        prop: "model",
        event: "modelChanged"
    }
})
export default class BrandQueryResultView extends Vue {
    public busyResult = new BusyObject<BrandQueryResult>();

    // Properties
    @Prop({ required: false })
    public model?: BrandQueryResult | null;

    @Prop({ required: false })
    public brandQueryHistoryItem?: BrandQueryHistoryItem;

    @Prop({ required: false })
    public brandQueryHistoryItemId?: string;

    @Prop({ required: false, default: 'vertical-grid', type: String })
    public view!: 'carousel' | 'list' | 'slider' | 'vertical-grid' | 'horizontal-grid';

    // Getter
    public get result(): BrandQueryResult | null {
        return this.model ?? this.busyResult.object;
    }
    public set result(value: BrandQueryResult | null) {
        this.busyResult.object = value;
        this.$emit('modelChanged', value);
    }

    public get itemsToShow(): number {
        return Math.ceil(window.innerWidth / 550);
    }

    // Watcher:
    @Watch('brandQueryHistoryItem')
    public onBrandQueryHistoryItemChanged() {
        this.loadResults();
    }

    @Watch('brandQueryHistoryItemId')
    public onBrandQueryHistoryItemIdChanged() {
        this.loadResults();
    }

    // Lifecycle Methods:
    public mounted() {
        if(!this.result) {
            this.loadResults();
        }
    }

    // Methods:
    public async loadResults() {
        await this.busyResult.load(async () => {
            if(this.brandQueryHistoryItem?.id) {
                return await BrandQueriesService.getBrandQueryHistoryItemResult(this.brandQueryHistoryItem.id);
            }
            else if(this.brandQueryHistoryItemId) {
                return await BrandQueriesService.getBrandQueryHistoryItemResult(this.brandQueryHistoryItemId);
            }
            else {
                return null;
            }
        });
    }

    public getIdOrIndex(item: BrandQueryResultItem | undefined | null, index: number): string {
        return item?.indexItem?.id ? `Id_${item.indexItem.id}` : `Index_${index}`;
    }

}
