












































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { BrandQueryHistoryItem, BrandQueryResult, StoredBrandQuery } from '@/api/braendz';

import Popup from '@/components/Popups/Popup.vue';
import BrandQueryResultView from '@/components/BrandQueryResults/BrandQueryResultView.vue';

@Component({
    components: {
        Popup,
        BrandQueryResultView
    },
    model: {
        prop: "model",
        event: "visibilityChanged"
    }
})
export default class BrandQueryResultPopup extends Vue {
    public visibility = false;
    public view: 'carousel' | 'list' | 'slider' | 'vertical-grid' | 'horizontal-grid' = 'vertical-grid';

    // Properties
    @Prop({ required: false })
    public model?: boolean;

    @Prop({ required: false })
    public brandQueryResult?: BrandQueryResult | null;

    @Prop({ required: false })
    public brandQueryHistoryItem?: BrandQueryHistoryItem;

    @Prop({ required: false })
    public storedBrandQuery?: StoredBrandQuery;

    @Prop({ required: false })
    public brandQueryHistoryItemId?: string;

    @Prop({ required: false })
    public title?: string;

    // Getter & Setter
    public get visible(): boolean {
        return this.model ?? this.visibility;
    }
    public set visible(value: boolean) {
        this.visibility = value;
        this.$emit('visibilityChanged', value);
    }

    public get storedBrandQueryId(): number | null {
        return this.storedBrandQuery?.id ?? null;
    }

    public get resultId(): string | null {
        return this.brandQueryResult?.id ?? this.brandQueryHistoryItem?.id ?? this.brandQueryHistoryItemId ?? null;
    }

    public get popupTitle(): string {
        return this.title ?? (this.executionUtc ? this.$d(new Date(this.executionUtc), 'dateTime') : null) ?? 'Result';
    }

    public get executionUtc(): string | null {
        return this.brandQueryHistoryItem?.executedUtc ?? this.brandQueryHistoryItem?.createdUtc ?? null;
    }

    // Lifecycle Methods:

    // Methods:
}
