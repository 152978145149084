var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mx-auto d-flex flex-column rounded-lg",
      attrs: {
        light: "",
        "max-width": "500",
        height: "100%",
        elevation: "3",
        rounded: "",
        loading: _vm.loading,
      },
    },
    [
      _c(
        "v-card-title",
        { staticStyle: { display: "block" } },
        [
          _c(
            "v-list-item",
            { attrs: { "two-line": "" } },
            [
              _vm.item.state === "Succeeded"
                ? _c(
                    "v-list-item-avatar",
                    [
                      _c(
                        "v-icon",
                        { attrs: { "x-large": "", color: "green darken-4" } },
                        [_vm._v("fa-light fa-circle-check")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.item.state === "Failed"
                ? _c(
                    "v-list-item-avatar",
                    [
                      _c(
                        "v-icon",
                        { attrs: { "x-large": "", color: "red darken-4" } },
                        [_vm._v("fa-light fa-circle-exclamation-check")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-list-item-content",
                [
                  _vm.executionOrCreationTimeUtc
                    ? _c(
                        "v-list-item-title",
                        { staticClass: "text-h5 text-truncate" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$d(
                                  new Date(_vm.executionOrCreationTimeUtc),
                                  "dateTime"
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.item.state === "Succeeded"
                    ? _c("v-list-item-subtitle", [_vm._v(" Result Available ")])
                    : _vm.item.state === "Failed"
                    ? _c("v-list-item-subtitle", [_vm._v(" Execution Failed ")])
                    : _c("v-list-item-subtitle", [_vm._v(" Unknown ")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-3" }),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { staticStyle: { height: "150px" }, attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "d-flex justify-center", attrs: { cols: "12" } },
                [
                  _vm.storedResultsPercentage
                    ? _c(
                        "v-progress-circular",
                        {
                          attrs: {
                            size: 100,
                            width: 15,
                            value: _vm.storedResultsPercentage,
                            color: "primary",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$n(
                                  _vm.storedResultsPercentage / 100,
                                  "percent"
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _c(
                        "v-progress-circular",
                        {
                          attrs: {
                            size: 100,
                            width: 15,
                            value: 0,
                            color: "primary",
                          },
                        },
                        [_vm._v(" N.A. ")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mb-2 mx-3" }),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "6" } }, [
                _vm.item.storedResultsCount
                  ? _c(
                      "span",
                      { staticClass: "d-block text-truncate body-1" },
                      [_vm._v(_vm._s(_vm.item.storedResultsCount))]
                    )
                  : _c(
                      "span",
                      {
                        staticClass:
                          "d-block text-truncate body-1 text--disabled",
                      },
                      [_vm._v("N.A.")]
                    ),
                _c("span", { staticClass: "caption d-block" }, [
                  _vm._v("Stored Results"),
                ]),
              ]),
              _c("v-col", { attrs: { cols: "6" } }, [
                _vm.item.totalResultsCount
                  ? _c(
                      "span",
                      { staticClass: "d-block text-truncate body-1" },
                      [_vm._v(_vm._s(_vm.item.totalResultsCount))]
                    )
                  : _c(
                      "span",
                      {
                        staticClass:
                          "d-block text-truncate body-1 text--disabled",
                      },
                      [_vm._v("N.A.")]
                    ),
                _c("span", { staticClass: "caption d-block" }, [
                  _vm._v("Total Results"),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-spacer"),
      _c("v-divider", { staticClass: "my-2 mx-3" }),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: {
                plain: "",
                disabled:
                  _vm.loading || !_vm.item.id || _vm.item.state !== "Succeeded",
              },
              on: { click: _vm.showBrandQueryResult },
            },
            [_c("span", [_vm._v("Results")])]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }