var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.brandQueryHistoryItems.isBusy
    ? _c(
        "div",
        { staticClass: "d-flex flex-column justify-center align-center" },
        [
          _c("v-progress-linear", {
            attrs: { indeterminate: "", color: "primary" },
          }),
        ],
        1
      )
    : _vm.brandQueryHistoryItems.list.length === 0
    ? _c(
        "div",
        { staticClass: "d-flex flex-column justify-center align-center" },
        [
          _c("span", { staticClass: "caption" }, [
            _vm._v("No results available"),
          ]),
        ]
      )
    : _vm.view === "carousel"
    ? _c("Carousel3dSlider", {
        attrs: { loop: true, width: 400, height: 800 },
        scopedSlots: _vm._u(
          [
            _vm._l(
              _vm.brandQueryHistoryItems.list,
              function (historyItem, index) {
                return {
                  key: "slide-" + index,
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          key: _vm.getIdOrIndex(historyItem, index),
                          staticClass: "pa-1",
                          staticStyle: { width: "100%", height: "100%" },
                        },
                        [
                          _c("BrandQueryHistoryItemTile", {
                            staticClass: "fill-height",
                            attrs: { item: historyItem },
                            on: {
                              showBrandQueryResult: _vm.showBrandQueryResult,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              }
            ),
          ],
          null,
          true
        ),
      })
    : _vm.view === "slider"
    ? _c(
        "vue-slick-carousel",
        {
          staticClass: "align-height",
          attrs: {
            arrows: false,
            accessibility: "",
            swipeToSlide: "",
            dots: "",
            infinite: "",
            focusOnSelect: "",
            centerMode: "",
            slidesToShow: _vm.itemsToShow,
            slidesToScroll: 1,
          },
        },
        _vm._l(_vm.brandQueryHistoryItems.list, function (historyItem, index) {
          return _c(
            "div",
            {
              key: _vm.getIdOrIndex(historyItem, index),
              staticClass: "fill-height pa-3",
            },
            [
              _c("BrandQueryHistoryItemTile", {
                staticClass: "fill-height",
                attrs: { item: historyItem },
                on: { showBrandQueryResult: _vm.showBrandQueryResult },
              }),
            ],
            1
          )
        }),
        0
      )
    : _vm.view === "list"
    ? _c(
        "v-list",
        { attrs: { dense: "", flat: "", expand: "", color: "transparent" } },
        [
          _vm._l(
            _vm.brandQueryHistoryItems.list,
            function (historyItem, index) {
              return _c(
                "v-list-item",
                {
                  key: _vm.getIdOrIndex(historyItem, index),
                  attrs: { dense: "", "active-class": "item-active" },
                  on: {
                    click: function ($event) {
                      return _vm.showBrandQueryResult(historyItem)
                    },
                  },
                },
                [
                  historyItem.state === "Succeeded"
                    ? _c(
                        "v-list-item-avatar",
                        { attrs: { tile: "" } },
                        [
                          _c("v-icon", { attrs: { color: "green darken-4" } }, [
                            _vm._v("fa-light fa-circle-check"),
                          ]),
                        ],
                        1
                      )
                    : historyItem.state === "Failed"
                    ? _c(
                        "v-list-item-avatar",
                        { attrs: { tile: "" } },
                        [
                          _c("v-icon", { attrs: { color: "red darken-4" } }, [
                            _vm._v("fa-light fa-circle-exclamation-check"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item-content",
                    [
                      historyItem.executedUtc || historyItem.createdUtc
                        ? _c("v-list-item-title", { staticClass: "body-1" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$d(
                                    new Date(
                                      historyItem.executedUtc
                                        ? historyItem.executedUtc
                                        : historyItem.createdUtc
                                        ? historyItem.createdUtc
                                        : new Date()
                                    ),
                                    "dateTime"
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      historyItem.state === "Succeeded"
                        ? _c("v-list-item-subtitle", [
                            _vm._v(" Result Available "),
                          ])
                        : historyItem.state === "Failed"
                        ? _c("v-list-item-subtitle", [
                            _vm._v(" Execution Failed "),
                          ])
                        : _c("v-list-item-subtitle", [_vm._v(" Unknown ")]),
                    ],
                    1
                  ),
                ],
                1
              )
            }
          ),
        ],
        2
      )
    : _vm.view === "grid"
    ? _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            _vm._l(
              _vm.brandQueryHistoryItems.list,
              function (historyItem, index) {
                return _c(
                  "v-col",
                  {
                    key: _vm.getIdOrIndex(historyItem, index),
                    attrs: { xl: "3", lg: "4", md: "6", xs: "12" },
                  },
                  [
                    _c("BrandQueryHistoryItemTile", {
                      staticClass: "fill-height",
                      attrs: { item: historyItem },
                      on: { showBrandQueryResult: _vm.showBrandQueryResult },
                    }),
                  ],
                  1
                )
              }
            ),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }