var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-main", [
    _c(
      "section",
      { staticClass: "pa-16", staticStyle: { "min-height": "100%" } },
      [_c("BrandDetails", { attrs: { brand: _vm.brand } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }