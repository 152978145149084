var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Popup", {
    attrs: {
      title: _vm.$t("executeStoredBrandQueryPopup.title"),
      "max-width": "700",
    },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [_vm._t("activator", null, null, { on: on, attrs: attrs })]
          },
        },
        {
          key: "default",
          fn: function () {
            return [
              _c(
                "v-card-text",
                { staticClass: "pt-5" },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.sizes,
                      "item-text": "text",
                      "item-value": "size",
                      label: _vm.$t("executeStoredBrandQueryPopup.size"),
                      "persistent-hint": "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.selectedSize,
                      callback: function ($$v) {
                        _vm.selectedSize = $$v
                      },
                      expression: "selectedSize",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("v-checkbox", {
                    staticClass: "pa-0",
                    attrs: {
                      label: _vm.$t("executeStoredBrandQueryPopup.save"),
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.saveResult,
                      callback: function ($$v) {
                        _vm.saveResult = $$v
                      },
                      expression: "saveResult",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.execute } },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("executeStoredBrandQueryPopup.execute"))
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.close } },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("executeStoredBrandQueryPopup.cancel"))
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }