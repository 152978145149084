

























import { BrandQueryExecutionOptions } from '@/api/braendz';
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';

import Popup from '@/components/Popups/Popup.vue';

@Component({
    components: {
        Popup
    },
    model: {
        prop: "model",
        event: "visibilityChanged"
    }
})
export default class ExecuteStoredBrandQueryPopup extends Vue {
    // Member
    public visibility = false;

    public selectedSize = 50;
    public saveResult = true;
    public sizes = [
        { size: 10, text: '10 Results' },
        { size: 25, text: '25 Results' },
        { size: 50, text: '50 Results' },
        { size: 75, text: '75 Results' },
        { size: 100, text: '100 Results' },
    ];

    // Properties
    @Prop({ required: false })
    public model?: boolean;

    @Prop({ required: false, default: 50, type: Number })
    public defaultSize!: number;

    // Getter & Setter
    public get visible(): boolean {
        return this.model ?? this.visibility;
    }
    public set visible(value: boolean) {
        this.visibility = value;
        this.$emit('visibilityChanged', value);
    }

    // Lifecycle Methods:
    public mounted() {
        this.selectedSize = this.defaultSize;
    }

    // Methods:
    @Watch('defaultSize')
    public onDefaultSizeChanged(value: number) {
        this.selectedSize = value;
    }

    public close(): void {
        this.visible = false;
    }

    public execute(): void {
        this.$emit('execute', { storeResult: this.saveResult, size: this.selectedSize } as BrandQueryExecutionOptions);
        this.close();
    }
}
