var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.view === "carousel"
    ? _c("Carousel3dSlider", {
        attrs: { loop: true, width: 400, height: 800 },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.brands, function (brand, index) {
              return {
                key: "slide-" + index,
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        key: index,
                        staticClass: "pa-1",
                        staticStyle: { width: "100%", height: "100%" },
                      },
                      [
                        _c("VerticalBrandTile", {
                          attrs: {
                            width: "100%",
                            height: "100%",
                            brand: brand.object,
                            loading: brand.isBusy,
                            canRemoveFromBookmarks: true,
                          },
                          on: { removeFromBookmarks: _vm.removeFromBookmarks },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            }),
          ],
          null,
          true
        ),
      })
    : _vm.view === "slider"
    ? _c(
        "vue-slick-carousel",
        {
          staticClass: "align-height",
          attrs: {
            arrows: false,
            accessibility: "",
            swipeToSlide: "",
            dots: "",
            infinite: "",
            focusOnSelect: "",
            centerMode: "",
            slidesToShow: _vm.brandItemsToShow,
            slidesToScroll: 1,
          },
        },
        _vm._l(_vm.brands, function (brand, index) {
          return _c(
            "div",
            { key: index, staticClass: "fill-height pa-3" },
            [
              _c("VerticalBrandTile", {
                staticClass: "fill-height",
                attrs: {
                  brand: brand.object,
                  loading: brand.isBusy,
                  canRemoveFromBookmarks: true,
                },
                on: { removeFromBookmarks: _vm.removeFromBookmarks },
              }),
            ],
            1
          )
        }),
        0
      )
    : _vm.view === "list"
    ? _c(
        "v-list",
        {
          attrs: { "two-line": "", flat: "", expand: "", color: "transparent" },
        },
        [
          _vm._l(_vm.brands, function (item, index) {
            return _c("brand-list-item", {
              key: index,
              attrs: { value: item.object, canRemoveFromBookmarks: true },
              on: { removeFromBookmarks: _vm.removeFromBookmarks },
            })
          }),
        ],
        2
      )
    : _vm.view === "grid"
    ? _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            _vm._l(_vm.brands, function (brand, index) {
              return _c(
                "v-col",
                {
                  key: _vm.getIdOrIndex(brand.object, index),
                  attrs: { xl: "3", lg: "4", md: "6", xs: "12" },
                },
                [
                  _c("VerticalBrandTile", {
                    staticClass: "fill-height",
                    attrs: {
                      brand: brand.object,
                      loading: brand.isBusy,
                      canRemoveFromBookmarks: true,
                    },
                    on: { removeFromBookmarks: _vm.removeFromBookmarks },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }