






































































































































































































































































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator';

import { AccountInfo } from "@azure/msal-browser";
import { BusyList, BusyObject } from '@/models/Busy';
import { BrandBookmarkContainer, BrandBookmarkContainersService, BrandQueriesService, BrandQueryExecutionOptions, BrandQueryHistoryItem, BrandQueryResult, StoredBrandQueriesService, StoredBrandQuery } from '@/api/braendz';
import { RobotOption } from '@/models/MetaTags';
import { IdTokenClaim } from '@/models/IdTokenClaim';

import Label from '@/components/Label.vue';

import DashboardCard from '@/components/Dashboard/DashboardCard.vue';
import BrandBookmarkContainerViewer from '@/components/BrandBookmarks/BrandBookmarkContainerViewer.vue';
import ConfirmationPopup from '@/components/Popups/ConfirmationPopup.vue';

import ConfigureStoredBrandQueryPopup from '@/components/StoredBrandQueries/ConfigureStoredBrandQueryPopup.vue';
import ExecuteStoredBrandQueryPopup from '@/components/StoredBrandQueries/ExecuteStoredBrandQueryPopup.vue';
import BrandQueryHistory from '@/components/BrandQueryResults/BrandQueryHistory.vue';
import BrandQueryResultPopup from '@/components/BrandQueryResults/BrandQueryResultPopup.vue';
import BrandQueryResultView from '@/components/BrandQueryResults/BrandQueryResultView.vue';

@Component({
    components: {
        Label,
        DashboardCard,
        BrandBookmarkContainerViewer,
        ConfirmationPopup,
        BrandQueryHistory,
        ConfigureStoredBrandQueryPopup,
        ExecuteStoredBrandQueryPopup,
        BrandQueryResultPopup,
        BrandQueryResultView
    },
    metaInfo() {
        return {
            title: this.$i18n.t("myBraendz.title").toString(),
            meta: [
                { name: 'robots', content: [RobotOption.NoIndex, RobotOption.NoFollow].join(',') }
            ]
        };
    }
})
export default class MyBraendz extends Vue {
    // Properties
    public navigationVisible = true;
    public selectedItem: BrandBookmarkContainer | StoredBrandQuery | null | undefined = null;
    public brandBookmarkContainerView: 'carousel' | 'list' | 'slider' | 'grid' = this.$vuetify.breakpoint.mdAndUp ? 'carousel' : 'list';

    public brandBookmarkContainers = new BusyList<BrandBookmarkContainer>();
    public storedBrandQueries = new BusyList<StoredBrandQuery>();

    public brandQueryHistoryView: 'carousel' | 'list' | 'slider' | 'grid' = 'carousel';
    public brandQueryResult = new BusyObject<BrandQueryResult>();
    public brandQueryResultView: 'carousel' | 'list' | 'slider' | 'vertical-grid' | 'horizontal-grid' = 'vertical-grid';

    // BrandQueryResultPopup:
    public showBrandQueryResultPopup = false;
    public brandQueryResultPopupHistoryItem: BrandQueryHistoryItem | null = null;
    public brandQueryResultPopupStoredBrandQuery: StoredBrandQuery | null = null;


    // Getter & Setter
    public get userAccount(): AccountInfo | null {
        return this.$store.state.userAccount;
    }

    public get userFirstname(): string | null {
        return (this.userAccount?.idTokenClaims as IdTokenClaim)?.given_name ?? null;
    }

    public get userDisplayName(): string | null {
        return this.userAccount?.name ?? this.userAccount?.username ?? null;
    }

    public get collapsableNavigation(): boolean {
        return this.$vuetify.breakpoint.mdAndDown;
    }

    public get showNavigation(): boolean {
        return !this.collapsableNavigation || this.navigationVisible;
    }
    public set showNavigation(value: boolean) {
        this.navigationVisible = value;
    }

    public get selectedBrandBookmarkContainer(): BrandBookmarkContainer | null {
        if(!this.selectedItem) return null;
        else if("brandBookmarks" in this.selectedItem) return this.selectedItem;
        else return null;
    }

    public get selectedStoredBrandQuery(): StoredBrandQuery | null {
        if(!this.selectedItem) return null;
        else if("agentExecutionFrequency" in this.selectedItem) return this.selectedItem;
        else return null;
    }

    // Watchers
    @Watch('selectedItem')
    public onSelectedItemChanged() {
        this.selectBrandQueryResult(null);
    }

    // Lifecycle Methods:
    public mounted(): void {
        // Initialize some stuff here:
        this.refreshBrandBookmarkContainers();
        this.refreshStoredBrandQueries();
    }

    // Methods:
    public getBookmarkKey(item: BrandBookmarkContainer | undefined | null, index: number): string {
        return item?.id ? `Bookmark_${item?.id}` : `Bookmark_${index}`;
    }

    public getQueryKey(item: StoredBrandQuery | undefined | null, index: number): string {
        return item?.id ? `Query_${item?.id}` : `Bookmark_${index}`;
    }

    public async refreshBrandBookmarkContainers() {
        await this.brandBookmarkContainers.load(async () => {
            return await BrandBookmarkContainersService.getBrandBookmarkContainersCurrentUserDefaultAccount();
        });
    }

    public async refreshStoredBrandQueries() {
        await this.storedBrandQueries.load(async () => {
            return await StoredBrandQueriesService.getStoredBrandQueriesCurrentUserDefaultAccount();
        });
    }

    public async deleteContainer(container: BrandBookmarkContainer) {
        await this.brandBookmarkContainers.delete(async () => {
            if(!container.id) {
                return null;
            }
            await BrandBookmarkContainersService.deleteBrandBookmarkContainer(container.id);
            return container;
        });
    }

    public async executeStoredBrandQuery(storedBrandQuery: StoredBrandQuery, options: BrandQueryExecutionOptions) {
        const brandQueryResult = await this.brandQueryResult.create(async () => {
            if(storedBrandQuery.id) {
                return await BrandQueriesService.executeStoredBrandQuery(storedBrandQuery.id, options);
            }
            return null;
        });

        // Add item to the history list:
        const selectedStoredBrandQueryHistory = this.$refs.selectedStoredBrandQueryHistory as BrandQueryHistory;
        if(brandQueryResult && selectedStoredBrandQueryHistory) {
            selectedStoredBrandQueryHistory.addHistoryItem(brandQueryResult);
        }
    }

    public async deleteStoredBrandQuery(storedBrandQuery: StoredBrandQuery): Promise<void> {
        await this.storedBrandQueries.delete(async () => {
            if(!storedBrandQuery.id) {
                return null;
            }
            await StoredBrandQueriesService.deleteStoredBrandQuery(storedBrandQuery.id);
            return storedBrandQuery;
        });
    }

    public showResultPopup(query: StoredBrandQuery, historyItem: BrandQueryHistoryItem) {
        this.brandQueryResultPopupStoredBrandQuery = query;
        this.brandQueryResultPopupHistoryItem = historyItem;
        this.showBrandQueryResultPopup = true;
    }

    public selectBrandQueryResult(historyItem: BrandQueryHistoryItem | null | undefined) {
        this.brandQueryResult.load(async () => {
            if(historyItem?.id) {
                return await BrandQueriesService.getBrandQueryHistoryItemResult(historyItem.id);
            }
            return null;
        });
    }

}
