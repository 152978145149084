

































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BusyList } from '@/models/Busy';
import { BrandQueryHistoryItem, BrandQueryHistoryService, StoredBrandQuery } from '@/api/braendz';

import VueSlickCarousel from 'vue-slick-carousel';
import Carousel3dSlider from '@/components/Carousels/Carousel3dSlider.vue';
import BrandQueryHistoryItemTile from '@/components/BrandQueryResults/BrandQueryHistoryItemTile.vue';

@Component({
    components: {
        BrandQueryHistoryItemTile,
        VueSlickCarousel,
        Carousel3dSlider
    }
})
export default class BrandQueryHistory extends Vue {
    // Member
    public brandQueryHistoryItems = new BusyList<BrandQueryHistoryItem>();

    // Properties
    @Prop({ required: true })
    public storedBrandQuery!: StoredBrandQuery;

    @Prop({ required: false, default: 'carousel', type: String })
    public view!: 'carousel' | 'list' | 'slider' | 'grid';

    // Getter & Setter
    public get itemsToShow(): number {
        return Math.ceil(window.innerWidth / 550);
    }
    
    // Watchers
    @Watch('storedBrandQuery')
    public onStoredBrandQueryChanged() {
        this.refreshBrandQueryHistoryItems();
    }

    // Lifecycle Methods:
    public mounted() {
        this.refreshBrandQueryHistoryItems();
    }

    // Methods:
    public async refreshBrandQueryHistoryItems() {
        await this.brandQueryHistoryItems.load(async () => {
            if(this.storedBrandQuery.id) {
                const count = this.storedBrandQuery.agentExecutionFrequency === 'Daily' ? 30 : this.storedBrandQuery.agentExecutionFrequency === 'Weekly' ? 12 : this.storedBrandQuery.agentExecutionFrequency === 'Monthly' ? 3 : 30;
                return await BrandQueryHistoryService.getBrandQueryHistory(this.storedBrandQuery.id, count);
            }
            return null;
        });
    }

    public addHistoryItem(item: BrandQueryHistoryItem) {
        this.brandQueryHistoryItems.list.push(item);
    }

    public getIdOrIndex(item: BrandQueryHistoryItem | undefined | null, index: number): string {
        return item?.id ? `Id_${item?.id}` : `Index_${index}`;
    }

    public showBrandQueryResult(historyItem: BrandQueryHistoryItem) {
        if(historyItem) {
            this.$emit('showBrandQueryResult', historyItem);
        }
    }
}
