var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Popup", {
    attrs: { title: _vm.popupTitle, fullscreen: true },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [_vm._t("activator", null, null, { on: on, attrs: attrs })]
          },
        },
        {
          key: "toolbar-append",
          fn: function () {
            return [
              _c(
                "v-btn-toggle",
                {
                  attrs: { mandatory: "", dense: "", group: "" },
                  model: {
                    value: _vm.view,
                    callback: function ($$v) {
                      _vm.view = $$v
                    },
                    expression: "view",
                  },
                },
                [
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "v-btn",
                        { attrs: { value: "carousel", text: "" } },
                        [
                          _c("v-icon", [
                            _vm._v("fa-light fa-rectangle-vertical-history"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    { attrs: { value: "slider", text: "" } },
                    [_c("v-icon", [_vm._v("fa-light fa-columns-3")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    { attrs: { value: "vertical-grid", text: "" } },
                    [_c("v-icon", [_vm._v("fa-light fa-grid")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    { attrs: { value: "horizontal-grid", text: "" } },
                    [_c("v-icon", [_vm._v("fa-light fa-diagram-cells")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    { attrs: { value: "list", text: "" } },
                    [_c("v-icon", [_vm._v("fa-light fa-list")])],
                    1
                  ),
                ],
                1
              ),
              _vm.storedBrandQueryId && _vm.resultId
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          icon: "",
                                          dark: "",
                                          to: {
                                            name: "StoredBrandQueryResult",
                                            params: {
                                              queryId: _vm.storedBrandQueryId,
                                              resultId: _vm.resultId,
                                            },
                                          },
                                          target: "_blank",
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        "fa-light fa-arrow-up-right-from-square"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4075501648
                      ),
                    },
                    [_c("span", [_vm._v("Open in new window")])]
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
        {
          key: "default",
          fn: function () {
            return [
              _c(
                "v-card-text",
                { staticClass: "pt-2 pb-0" },
                [
                  _c("BrandQueryResultView", {
                    attrs: {
                      brandQueryHistoryItem: _vm.brandQueryHistoryItem,
                      brandQueryHistoryItemId: _vm.brandQueryHistoryItemId,
                      view: _vm.view,
                    },
                    model: {
                      value: _vm.brandQueryResult,
                      callback: function ($$v) {
                        _vm.brandQueryResult = $$v
                      },
                      expression: "brandQueryResult",
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }