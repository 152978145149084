var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.busyResult.isBusy
    ? _c(
        "div",
        { staticClass: "d-flex flex-column justify-center align-center" },
        [
          _c("v-progress-linear", {
            attrs: { indeterminate: "", color: "primary" },
          }),
        ],
        1
      )
    : !_vm.result || !_vm.result.items || _vm.result.items.length === 0
    ? _c(
        "div",
        { staticClass: "d-flex flex-column justify-center align-center" },
        [
          _c("span", { staticClass: "caption" }, [
            _vm._v("No results available"),
          ]),
        ]
      )
    : _vm.view === "carousel"
    ? _c("Carousel3dSlider", {
        attrs: { loop: true, width: 400, height: 800 },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.result.items, function (resultItem, index) {
              return {
                key: "slide-" + index,
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        key: _vm.getIdOrIndex(resultItem, index),
                        staticClass: "pa-1",
                        staticStyle: { width: "100%", height: "100%" },
                      },
                      [
                        _c("VerticalBrandTile", {
                          attrs: {
                            width: "100%",
                            height: "100%",
                            brand: resultItem,
                            canAddToBookmarks: true,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            }),
          ],
          null,
          true
        ),
      })
    : _vm.view === "slider"
    ? _c(
        "vue-slick-carousel",
        {
          staticClass: "align-height",
          attrs: {
            arrows: false,
            accessibility: "",
            swipeToSlide: "",
            dots: "",
            infinite: "",
            focusOnSelect: "",
            centerMode: "",
            slidesToShow: _vm.itemsToShow,
            slidesToScroll: 1,
          },
        },
        _vm._l(_vm.result.items, function (resultItem, index) {
          return _c(
            "div",
            {
              key: _vm.getIdOrIndex(resultItem, index),
              staticClass: "fill-height pa-3",
            },
            [
              _c("VerticalBrandTile", {
                staticClass: "fill-height",
                attrs: { brand: resultItem, canAddToBookmarks: true },
              }),
            ],
            1
          )
        }),
        0
      )
    : _vm.view === "list"
    ? _c(
        "v-list",
        {
          attrs: { "two-line": "", flat: "", expand: "", color: "transparent" },
        },
        [
          _vm._l(_vm.result.items, function (resultItem, index) {
            return _c("brand-list-item", {
              key: _vm.getIdOrIndex(resultItem, index),
              attrs: { value: resultItem, canAddToBookmarks: true },
            })
          }),
        ],
        2
      )
    : _vm.view === "vertical-grid"
    ? _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            _vm._l(_vm.result.items, function (resultItem, index) {
              return _c(
                "v-col",
                {
                  key: _vm.getIdOrIndex(resultItem, index),
                  attrs: { xl: "3", lg: "4", md: "6", xs: "12" },
                },
                [
                  _c("VerticalBrandTile", {
                    staticClass: "fill-height",
                    attrs: { brand: resultItem, canAddToBookmarks: true },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm.view === "horizontal-grid"
    ? _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            _vm._l(_vm.result.items, function (resultItem, index) {
              return _c(
                "v-col",
                {
                  key: _vm.getIdOrIndex(resultItem, index),
                  attrs: { cols: "12" },
                },
                [
                  _c("HorizontalBrandTile", {
                    attrs: { brand: resultItem, canAddToBookmarks: true },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }