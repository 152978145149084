

































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { BrandQueryHistoryItem } from '@/api/braendz';

@Component({
    components: {
    }
})
export default class BrandQueryHistoryItemTile extends Vue {
    // Properties
    @Prop({ required: true })
    public item!: BrandQueryHistoryItem;

    @Prop({ required: false, default: false, type: Boolean })
    public loading!: boolean;

    // Getter
    public get executionOrCreationTimeUtc(): string | null | undefined {
        return this.item.executedUtc ?? this.item.createdUtc;
    }

    public get storedResultsPercentage(): number | null {
        if(this.item.totalResultsCount && this.item.storedResultsCount) {
            return this.item.storedResultsCount / this.item.totalResultsCount * 100;
        }
        return null;
    }

    // Methods
    public showBrandQueryResult(): void {
        if(this.item.id) {
            this.$emit('showBrandQueryResult', this.item);
        }
    }
}
