

































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BusyObject } from '@/models/Busy';
import { Brand, BrandBookmarkContainer, BrandBookmarksService, BrandsService } from '@/api/braendz';

import VueSlickCarousel from 'vue-slick-carousel';
import Carousel3dSlider from '@/components/Carousels/Carousel3dSlider.vue';
import BrandListItem from '@/components/Brands/BrandListItem.vue';
import VerticalBrandTile from '@/components/Brands/VerticalBrandTile.vue';


@Component({
    components: {
        BrandListItem,
        VerticalBrandTile,
        VueSlickCarousel,
        Carousel3dSlider
    }
})
export default class BrandBookmarkContainerViewer extends Vue {
    // Member
    public brands: BusyObject<Brand>[] = [];

    // Properties
    @Prop({ required: true })
    public container!: BrandBookmarkContainer;

    @Prop({ required: false, default: 'carousel', type: String })
    public view!: 'carousel' | 'list' | 'slider' | 'grid';

    // Getter & Setter
    public get brandItemsToShow(): number {
        return Math.ceil(window.innerWidth / 550);
    }
    
    // Watchers
    @Watch('container')
    public onContainerChanged() {
        this.refreshBrands();
    }

    // Lifecycle Methods:
    public mounted() {
        this.refreshBrands();
    }

    // Methods:
    public async refreshBrands() {
        const result: BusyObject<Brand>[] = [];
        if(this.container.brandBookmarks) {
            for(const bookmark of this.container.brandBookmarks) {
                const busyBrand = new BusyObject<Brand>();
                result.push(busyBrand);
                busyBrand.load(async () => {
                    return BrandsService.getBrand(bookmark.id);
                });
            }
        }
        this.brands = result;
    }

    public getIdOrIndex(item: Brand | undefined | null, index: number): string {
        return item?.id ? `Id_${item?.id}` : `Index_${index}`;
    }

    public async removeFromBookmarks(brandId: string | null) {
        const existingBrand = this.brands.find(b => b.object?.id === brandId);

        if(existingBrand) {
            await existingBrand.delete(async () => {
                if(this.container.id && brandId) {
                    await BrandBookmarksService.deleteBrandBookmark(this.container.id, brandId);
                    this.brands = this.brands.filter(b => b !== existingBrand);
                }
                return existingBrand.object;
            });
        }
        else {
            if(this.container.id && brandId) {
                await BrandBookmarksService.deleteBrandBookmark(this.container.id, brandId);
                await this.refreshBrands();
            }
        }
    }
}
