var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    { ref: "main", staticClass: "bg-light" },
    [
      _vm.userAccount
        ? [
            _c(
              "div",
              {
                staticClass: "d-flex align-stretch",
                staticStyle: { "min-height": "100%" },
              },
              [
                _c(
                  "v-navigation-drawer",
                  {
                    class: {
                      "flex-shrink-1": true,
                      "height-unset": !_vm.collapsableNavigation,
                    },
                    attrs: {
                      color: "background-light",
                      absolute: _vm.collapsableNavigation,
                      clipped: "",
                      floating: "",
                    },
                    model: {
                      value: _vm.showNavigation,
                      callback: function ($$v) {
                        _vm.showNavigation = $$v
                      },
                      expression: "showNavigation",
                    },
                  },
                  [
                    _c(
                      "v-list",
                      {
                        attrs: {
                          nav: "",
                          dense: "",
                          shaped: "",
                          color: "transparent",
                        },
                      },
                      [
                        _c(
                          "v-list-item-group",
                          {
                            model: {
                              value: _vm.selectedItem,
                              callback: function ($$v) {
                                _vm.selectedItem = $$v
                              },
                              expression: "selectedItem",
                            },
                          },
                          [
                            _c(
                              "v-list-item",
                              {
                                staticClass: "mt-5",
                                attrs: { value: null, ripple: false },
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  { staticClass: "mr-3" },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { left: "", small: "" } },
                                      [_vm._v("fa-solid fa-grid-2")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Dashboard"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-subheader",
                              { staticClass: "text-uppercase mt-5" },
                              [_vm._v("Pinnwand")]
                            ),
                            _vm._l(
                              _vm.brandBookmarkContainers.list,
                              function (bookmark, index) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: _vm.getBookmarkKey(bookmark, index),
                                    attrs: { value: bookmark, ripple: false },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      { staticClass: "mr-3" },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { left: "", small: "" } },
                                          [_vm._v("fa-light fa-star")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(bookmark.name)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            _c(
                              "v-subheader",
                              { staticClass: "text-uppercase mt-5" },
                              [_vm._v("Suchen")]
                            ),
                            _vm._l(
                              _vm.storedBrandQueries.list,
                              function (storedBrandQuery, index) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: _vm.getQueryKey(
                                      storedBrandQuery,
                                      index
                                    ),
                                    attrs: {
                                      value: storedBrandQuery,
                                      ripple: false,
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      { staticClass: "mr-3" },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { left: "", small: "" } },
                                          [
                                            _vm._v(
                                              "fa-light fa-magnifying-glass"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(storedBrandQuery.name)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-scroll-y-transition",
                  {
                    staticClass: "d-flex flex-column",
                    staticStyle: { width: "100%" },
                    attrs: { group: "", "leave-absolute": "" },
                  },
                  [
                    !_vm.selectedItem
                      ? _c(
                          "v-sheet",
                          {
                            key: "dashboard",
                            attrs: { width: "100%", color: "transparent" },
                          },
                          [
                            _c(
                              "v-toolbar",
                              { key: "toolbar", attrs: { elevation: "0" } },
                              [
                                _vm.collapsableNavigation
                                  ? _c("v-app-bar-nav-icon", {
                                      on: {
                                        click: function ($event) {
                                          _vm.showNavigation =
                                            !_vm.showNavigation
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.userFirstname
                                  ? _c("v-toolbar-title", [
                                      _vm._v(
                                        "Hi " + _vm._s(_vm.userFirstname) + "!"
                                      ),
                                    ])
                                  : _vm.userDisplayName
                                  ? _c("v-toolbar-title", [
                                      _vm._v(
                                        "Hi " +
                                          _vm._s(_vm.userDisplayName) +
                                          "!"
                                      ),
                                    ])
                                  : _c("v-toolbar-title", [
                                      _vm._v("Dashboard"),
                                    ]),
                              ],
                              1
                            ),
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          xl: "2",
                                          lg: "4",
                                          md: "6",
                                          sm: "6",
                                          xs: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            attrs: {
                                              elevation: "3",
                                              color: "white",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "Label",
                                                  {
                                                    attrs: {
                                                      text: "Ihr Abonnement",
                                                      location: "bottom",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "dashboard-info-data",
                                                      },
                                                      [_vm._v("Standard")]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          xl: "2",
                                          lg: "4",
                                          md: "6",
                                          sm: "6",
                                          xs: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            attrs: {
                                              elevation: "3",
                                              color: "white",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "Label",
                                                  {
                                                    attrs: {
                                                      text: "Noch eine Info",
                                                      location: "bottom",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "dashboard-info-data",
                                                      },
                                                      [_vm._v("42")]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "section-title mt-5" },
                                        [_c("h1", [_vm._v("Ihre Pinnwände")])]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  _vm._l(
                                    _vm.brandBookmarkContainers.list,
                                    function (container, index) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: _vm.getBookmarkKey(
                                            container,
                                            index
                                          ),
                                          attrs: {
                                            xl: "4",
                                            lg: "6",
                                            md: "12",
                                            cols: "12",
                                          },
                                        },
                                        [
                                          _c("dashboard-card", {
                                            attrs: {
                                              title: container.name,
                                              color: "white",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "content",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "brand-bookmark-container-viewer",
                                                        {
                                                          attrs: {
                                                            container:
                                                              container,
                                                            view: "list",
                                                          },
                                                        }
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "content-nodata",
                                                  fn: function () {
                                                    return [_vm._v(" TODO? ")]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "actions",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: { text: "" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.selectedItem =
                                                                container
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Mehr ")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "section-title mt-5" },
                                        [
                                          _c("h1", [
                                            _vm._v("Ihre gespeicherten Suchen"),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  _vm._l(
                                    _vm.storedBrandQueries.list,
                                    function (storedBrandQuery, index) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: _vm.getQueryKey(
                                            storedBrandQuery,
                                            index
                                          ),
                                          attrs: {
                                            xl: "4",
                                            lg: "6",
                                            md: "12",
                                            cols: "12",
                                          },
                                        },
                                        [
                                          _c("dashboard-card", {
                                            attrs: {
                                              title: storedBrandQuery.name,
                                              color: "white",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "content",
                                                  fn: function () {
                                                    return [
                                                      _c("BrandQueryHistory", {
                                                        attrs: {
                                                          storedBrandQuery:
                                                            storedBrandQuery,
                                                          view: "list",
                                                        },
                                                        on: {
                                                          showBrandQueryResult:
                                                            function (
                                                              historyItem
                                                            ) {
                                                              return _vm.showResultPopup(
                                                                storedBrandQuery,
                                                                historyItem
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "content-nodata",
                                                  fn: function () {
                                                    return [_vm._v(" TODO? ")]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "actions",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: { text: "" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.selectedItem =
                                                                storedBrandQuery
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Mehr ")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectedBrandBookmarkContainer !== null
                      ? _c(
                          "v-sheet",
                          {
                            key: "bookmark",
                            attrs: { width: "100%", color: "transparent" },
                          },
                          [
                            _c(
                              "v-toolbar",
                              { key: "toolbar", attrs: { elevation: "0" } },
                              [
                                _vm.collapsableNavigation
                                  ? _c("v-app-bar-nav-icon", {
                                      on: {
                                        click: function ($event) {
                                          _vm.showNavigation =
                                            !_vm.showNavigation
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _c("v-toolbar-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.selectedBrandBookmarkContainer.name
                                    )
                                  ),
                                ]),
                                _c("v-spacer"),
                                _c(
                                  "v-btn-toggle",
                                  {
                                    attrs: {
                                      mandatory: "",
                                      dense: "",
                                      group: "",
                                    },
                                    model: {
                                      value: _vm.brandBookmarkContainerView,
                                      callback: function ($$v) {
                                        _vm.brandBookmarkContainerView = $$v
                                      },
                                      expression: "brandBookmarkContainerView",
                                    },
                                  },
                                  [
                                    _vm.$vuetify.breakpoint.mdAndUp
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              value: "carousel",
                                              text: "",
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                "fa-light fa-rectangle-vertical-history"
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-btn",
                                      { attrs: { value: "slider", text: "" } },
                                      [
                                        _c("v-icon", [
                                          _vm._v("fa-light fa-columns-3"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      { attrs: { value: "grid", text: "" } },
                                      [
                                        _c("v-icon", [
                                          _vm._v("fa-light fa-grid"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      { attrs: { value: "list", text: "" } },
                                      [
                                        _c("v-icon", [
                                          _vm._v("fa-light fa-list"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-spacer"),
                                _c("ConfirmationPopup", {
                                  attrs: {
                                    yes: "",
                                    cancel: "",
                                    question: "",
                                    title: "Löschen",
                                    text: "Möchten Sie diese Pinnwand unwiderruflich löschen?",
                                  },
                                  on: {
                                    yes: function ($event) {
                                      return _vm.deleteContainer(
                                        _vm.selectedBrandBookmarkContainer
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      plain: "",
                                                      disabled:
                                                        !_vm
                                                          .selectedBrandBookmarkContainer
                                                          .id,
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { left: "" } },
                                                  [
                                                    _vm._v(
                                                      "fa-light fa-trash-can"
                                                    ),
                                                  ]
                                                ),
                                                _c("span", [
                                                  _vm._v("Pinnwand löschen"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3022777779
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            attrs: {
                                              elevation:
                                                _vm.brandBookmarkContainerView ===
                                                "list"
                                                  ? 3
                                                  : 0,
                                              color:
                                                _vm.brandBookmarkContainerView ===
                                                "list"
                                                  ? "white"
                                                  : "transparent",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "brand-bookmark-container-viewer",
                                                  {
                                                    attrs: {
                                                      container:
                                                        _vm.selectedBrandBookmarkContainer,
                                                      view: _vm.brandBookmarkContainerView,
                                                    },
                                                  }
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectedStoredBrandQuery !== null
                      ? _c(
                          "v-sheet",
                          {
                            key: "query",
                            attrs: { width: "100%", color: "transparent" },
                          },
                          [
                            _c(
                              "v-toolbar",
                              { key: "toolbar", attrs: { elevation: "0" } },
                              [
                                _vm.collapsableNavigation
                                  ? _c("v-app-bar-nav-icon", {
                                      on: {
                                        click: function ($event) {
                                          _vm.showNavigation =
                                            !_vm.showNavigation
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _c("v-toolbar-title", [
                                  _vm._v(
                                    _vm._s(_vm.selectedStoredBrandQuery.name)
                                  ),
                                ]),
                                _c("v-spacer"),
                                _c(
                                  "v-btn-toggle",
                                  {
                                    attrs: {
                                      mandatory: "",
                                      dense: "",
                                      group: "",
                                    },
                                    model: {
                                      value: _vm.brandQueryHistoryView,
                                      callback: function ($$v) {
                                        _vm.brandQueryHistoryView = $$v
                                      },
                                      expression: "brandQueryHistoryView",
                                    },
                                  },
                                  [
                                    _vm.$vuetify.breakpoint.mdAndUp
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              value: "carousel",
                                              text: "",
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                "fa-light fa-rectangle-vertical-history"
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-btn",
                                      { attrs: { value: "slider", text: "" } },
                                      [
                                        _c("v-icon", [
                                          _vm._v("fa-light fa-columns-3"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          value: "vertical-grid",
                                          text: "",
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("fa-light fa-grid"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          value: "horizontal-grid",
                                          text: "",
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("fa-light fa-diagram-cells"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      { attrs: { value: "list", text: "" } },
                                      [
                                        _c("v-icon", [
                                          _vm._v("fa-light fa-list"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-spacer"),
                                _c("ExecuteStoredBrandQueryPopup", {
                                  attrs: {
                                    defaultSize: _vm.selectedStoredBrandQuery
                                      .settings.query.limit
                                      ? _vm.selectedStoredBrandQuery.settings
                                          .query.limit
                                      : _vm.selectedStoredBrandQuery.settings
                                          .query.size,
                                  },
                                  on: {
                                    execute: function (options) {
                                      return _vm.executeStoredBrandQuery(
                                        _vm.selectedStoredBrandQuery,
                                        options
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      plain: "",
                                                      disabled:
                                                        !_vm
                                                          .selectedStoredBrandQuery
                                                          .id,
                                                      loading:
                                                        _vm.brandQueryResult
                                                          .isBusy,
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { left: "" } },
                                                  [_vm._v("fa-light fa-play")]
                                                ),
                                                _c("span", [_vm._v("Run")]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3745950596
                                  ),
                                }),
                                _c("ConfigureStoredBrandQueryPopup", {
                                  attrs: {
                                    storedBrandQuery:
                                      _vm.selectedStoredBrandQuery,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      plain: "",
                                                      disabled:
                                                        !_vm
                                                          .selectedStoredBrandQuery
                                                          .id,
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { left: "" } },
                                                  [_vm._v("fa-light fa-gears")]
                                                ),
                                                _c("span", [
                                                  _vm._v("Configure"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2236621794
                                  ),
                                }),
                                _c("ConfirmationPopup", {
                                  attrs: {
                                    yes: "",
                                    cancel: "",
                                    question: "",
                                    title: "Löschen",
                                    text: "Möchten Sie diese gespeicherte Suche unwiderruflich löschen?",
                                  },
                                  on: {
                                    yes: function ($event) {
                                      return _vm.deleteStoredBrandQuery(
                                        _vm.selectedStoredBrandQuery
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      plain: "",
                                                      disabled:
                                                        !_vm
                                                          .selectedStoredBrandQuery
                                                          .id,
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { left: "" } },
                                                  [
                                                    _vm._v(
                                                      "fa-light fa-trash-can"
                                                    ),
                                                  ]
                                                ),
                                                _c("span", [_vm._v("Delete")]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    972784140
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            attrs: {
                                              elevation:
                                                _vm.brandQueryHistoryView ===
                                                "list"
                                                  ? 3
                                                  : 0,
                                              color:
                                                _vm.brandQueryHistoryView ===
                                                "list"
                                                  ? "white"
                                                  : "transparent",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              [
                                                _c("BrandQueryHistory", {
                                                  ref: "selectedStoredBrandQueryHistory",
                                                  attrs: {
                                                    storedBrandQuery:
                                                      _vm.selectedStoredBrandQuery,
                                                    view: _vm.brandQueryHistoryView,
                                                  },
                                                  on: {
                                                    showBrandQueryResult:
                                                      _vm.selectBrandQueryResult,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.brandQueryResult.object
                              ? [
                                  _c(
                                    "v-toolbar",
                                    { attrs: { elevation: "0" } },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn-toggle",
                                        {
                                          attrs: {
                                            mandatory: "",
                                            dense: "",
                                            group: "",
                                          },
                                          model: {
                                            value: _vm.brandQueryResultView,
                                            callback: function ($$v) {
                                              _vm.brandQueryResultView = $$v
                                            },
                                            expression: "brandQueryResultView",
                                          },
                                        },
                                        [
                                          _vm.$vuetify.breakpoint.mdAndUp
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    value: "carousel",
                                                    text: "",
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "fa-light fa-rectangle-vertical-history"
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                value: "slider",
                                                text: "",
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("fa-light fa-columns-3"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                value: "vertical-grid",
                                                text: "",
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("fa-light fa-grid"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                value: "horizontal-grid",
                                                text: "",
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  "fa-light fa-diagram-cells"
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                value: "list",
                                                text: "",
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("fa-light fa-list"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  attrs: {
                                                    elevation:
                                                      _vm.brandQueryResultView ===
                                                      "list"
                                                        ? 3
                                                        : 0,
                                                    color:
                                                      _vm.brandQueryResultView ===
                                                      "list"
                                                        ? "white"
                                                        : "transparent",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c(
                                                        "BrandQueryResultView",
                                                        {
                                                          attrs: {
                                                            view: _vm.brandQueryResultView,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .brandQueryResult
                                                                .object,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.brandQueryResult,
                                                                "object",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "brandQueryResult.object",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _vm._v(
                        " TODO: You must be logged in to view this page. "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
      _c("BrandQueryResultPopup", {
        attrs: {
          brandQueryHistoryItem: _vm.brandQueryResultPopupHistoryItem,
          storedBrandQuery: _vm.brandQueryResultPopupStoredBrandQuery,
        },
        model: {
          value: _vm.showBrandQueryResultPopup,
          callback: function ($$v) {
            _vm.showBrandQueryResultPopup = $$v
          },
          expression: "showBrandQueryResultPopup",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }